import { useEffect, useState } from 'react';
import globaStore from '../../../global/globastore';
import styles from './home-trips.module.scss'
import LoadingSpinner from '../../shared/components/loading-spinner/loading-spinner';
import Trip from '../../shared/components/trip/trip';

const HomeTrips = () => {
    const {trips} = globaStore()

    const [upcomingTrips, setUpcomingTrips] = useState([])
    const [loadingTrips, setLoadingTrips] = useState(true)

    useEffect(() => {
        if (trips.length) {
            let today = new Date()
            let sortedTrips = trips
                .filter((t) => new Date(t.tripOpts.calendarStart) >= today || new Date(t.tripOpts.calendarEnd) >= today)
                .sort((a,b) => new Date(a.tripOpts.calendarStart) - new Date(b.tripOpts.calendarStart))
            setUpcomingTrips(sortedTrips.slice(0, 4))
            setLoadingTrips(false)
        } else {
            setLoadingTrips(false)
        } 
    }, [trips])

    return (
        <>
            {loadingTrips ? (
                <div className={styles.tripsContainer}>
                    <LoadingSpinner/>
                </div>
                ) : (
                <>
                    {upcomingTrips.length ?
                    <div className={styles.tripsContainer}>
                        <div className={styles.tripsHeader}>
                            Upcoming Trips
                        </div>
                        <div className={styles.trips}>
                            {upcomingTrips.map((trip, idx) => (
                                <div className={styles.tripContainer} key={idx}>
                                    <Trip trip={trip}></Trip>
                                </div>
                            ))}
                        </div>
                    </div>
                    :
                    <></>
                    }
                </>
            )}
        </>
    );
};

export default HomeTrips;