import styles from './city-config.module.scss'
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en'
import { useCallback, useEffect, useRef, useState } from 'react';
import useGoogleMapsApi from '../../../../utils/google/useGoogleMapsApi';
import { isMobile } from 'react-device-detect';

const CityConfig = ({idx, cities, setCities}) => {
    const [value, setValue] = useState('')
    const datepickerRef = useRef(null)
    const dateInputRef = useRef(null)
    const touchStartYRef = useRef(0);
    const googleMapsApi = useGoogleMapsApi()
    let autocompleteEl = useRef(null)
    let placesAutocomplete = useRef(null)
    let autocompleteListener = useRef(null)
    let city = cities[idx]

    useEffect(() => {
        if(!placesAutocomplete.current && googleMapsApi){
            placesAutocomplete.current = new googleMapsApi.places.Autocomplete(
                autocompleteEl.current,
                {
                    types: ['(cities)'],
                    fields: ["formatted_address", "name", "address_components", "geometry"],
                }            
            );
            autocompleteListener.current = placesAutocomplete.current.addListener('place_changed', onPlaceChanged);
        }
    }, [placesAutocomplete, googleMapsApi])

    const handleTouchStart = useCallback((e) => {
        touchStartYRef.current = e.touches[0].clientY;
    }, []);

    const handleTouchMove = useCallback((e) => {
        if (!datepickerRef.current || !datepickerRef.current.visible) return;

        const touchEndY = e.touches[0].clientY;
        const deltaY = touchEndY - touchStartYRef.current;

        // Adjust this threshold as needed
        if (Math.abs(deltaY) > 30) {
            datepickerRef.current.hide();
        }
    }, []);

    useEffect(() => {
        if (!datepickerRef.current) {
            datepickerRef.current = new AirDatepicker(dateInputRef.current, {
                locale: localeEn,
                range: true,
                dynamicRange: true,
                autoClose: true,
                position: 'bottom center',
                multipleDatesSeparator: '  -  ',
                isMobile,
                onSelect: ({formattedDate}) => {
                    onDateChange(formattedDate)
                }
            })
        }

        // Add touch event listeners
        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchmove', handleTouchMove);

        return () => {
            // Clean up event listeners
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
        };
    }, [datepickerRef, handleTouchStart, handleTouchMove])

    useEffect(() => {
        if (datepickerRef.current){
            datepickerRef.current.update({
                onSelect: ({formattedDate}) => onDateChange(formattedDate)
            }) 
        }

        if (autocompleteListener.current){
            window.google.maps.event.removeListener(autocompleteListener.current)
            autocompleteListener.current = placesAutocomplete.current.addListener('place_changed', onPlaceChanged);
        }
    }, [cities])

    function onDateChange(date){
        if (date[0] && date[1]){
            if (new Date(date[0]) > new Date(date[1])){
                [date[0], date[1]] = [date[1], date[0]]
            }
        }
        city.start = date[0]
        city.end = date[1]

        if (date[0] && date[1]){
            setCities([...cities])
        }
        
        if (date[1]){
            datepickerRef.current.hide()
        }
    }

    const onPlaceChanged = () => {
        const place = placesAutocomplete.current.getPlace()

        city.formattedAddress = place.formatted_address
        city.validAddress = true
        setValue(place.formatted_address)

        if (place.address_components){
            city.country = place.address_components[place.address_components.length - 1]
        }

        if (place.name) {
            city.name = place.name
        }

        if (place.geometry) {
            city.geometry = {
                location: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                }
            }
        }

        setCities([...cities])
    }

    const handleOnBlur = () => {
        setValue(city.formattedAddress)
    }

    const handleOnChange = (e) => {
        setValue(e.target.value)
    }

    return (
        <div className={styles.cityConfigInputs}>
            <input 
                type="text"
                ref={autocompleteEl}
                placeholder='Input City (required)'
                value={value}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                id="city-config-input"
                name="city-config-input"
            />
            <input readOnly className='sc-btn-div' placeholder="Dates (required)" ref={dateInputRef}></input>
        </div>
    )
}

export default CityConfig